<template>
  <v-chip :color="$vuetify.theme.isDark ? undefined: 'white'">
    <div class="d-flex flex-row align-center">
      <v-icon class="mr-6">{{$icon('i.Menu')}}</v-icon>
      <div>
        <span v-if="valItem.id === null && valItem.type"> <v-icon small>{{$icon(`i.${toPascalCase(valItem.type)}`)}}</v-icon> {{$t('t.None')}}</span>
        <document-picker-list-item-ref
          class="filter-label"
          v-else-if="computedItem.id"
          :category-props="{ visible: false }"
          :item="computedItem"
          show-icon
        />
      </div>
      <slot
        name="pins"
        :item="computedItem"
      />
    </div>
  </v-chip>
</template>

<script>
export default {
  components: {
    DocumentPickerListItemRef: () => import('@/components/document-picker-list-item-ref')
  },
  computed: {
    computedItem () {
      return this.valItem?.type ? this.valItem : { id: this.valItem, type: 'auto-filters' }
    }
  },
  props: {
    valItem: [Object, String]
  }
}
</script>
